<template>
  <Dialog
    v-model="useDialogStore().paymentResponseSuccess.isOpen"
    size="small-hug"
    :persistent="useDialogStore().paymentResponseSuccess.persistent"
  >
    <div
      v-if="isLoading"
      v-loading="true"
      class="w-full h-[19.15rem]"
    />
    <div
      v-else
      class="flex flex-col items-center py-6"
    >
      <div>
        <Lottie
          :animation-data="lottieSuccess"
          :height="150"
          :width="150"
          :loop="false"
          :delay="100"
        />
      </div>
      <div class="text-center mb-6">
        <h2 class="text-base font-medium mb-1">
          {{ useT('plan.purchase.thankYou') }}
        </h2>
        <div class="text-gray-500 text-sm">
          {{ useT('plan.purchase.planIsReady') }}
        </div>
      </div>
      <div>
        <nuxt-link
          v-if="useDialogStore().paymentResponseSuccess.persistent"
          to="/"
          @click="useDialogStore().close('paymentResponseSuccess')"
        >
          <Button
            type="regular-outline"
          >
            {{ useT('plan.purchase.continueToWorkspace') }}
          </Button>
        </nuxt-link>

        <Button
          v-else
          type="regular-outline"
          @click="useDialogStore().close('paymentResponseSuccess')"
        >
          {{ useT('poly.close') }}
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import lottieSuccess from '~/assets/json/lottie/success.json';

// Refs
const isLoading = ref(false);

// Lifecycle
onMounted(async() => {
  if (useRoute().query?.payment_response) {
    isLoading.value = true;

    const response = await useProductsStore().getCheckout({
      sessionId: useRoute().query.payment_response,
    });

    if (response?.status === 'complete' && response?.payment_status === 'paid') {
      useDialogStore().open('paymentResponseSuccess', {
        persistent: useRoute()?.name?.includes('quote'),
      });
    } else {
      useModalStore().open({
        type: 'danger',
        icon: 'credit-card-x',
        title: useT('plan.payment.failed'),
        description: useT('plan.payment.failed.description'),
        submitButtonText: '',
        cancelButtonText: useT('poly.close'),
      });
    }

    isLoading.value = false;

    // remove payment_response from url
    useRouter().replace({
      query: {
        ...useRoute().query,
        payment_response: undefined,
      },
    });
  }
});
</script>
